// @ts-nocheck
import 'es6-promise/auto';

import Vue from "vue";
import VueRouter from 'vue-router'

import store from "./store";
import router from "./router";

import config from "./store/modules/config";

import GullKit from "./plugins/gull.kit"; //needs to be stripped, not really needed

import i18n from "./lang/lang";
import Breadcumb from "./components/breadcumb";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);

import AreYouSure from '@c/AreYouSure';
Vue.component("AreYouSure", AreYouSure);

import VueEllipseProgress from 'vue-ellipse-progress';
Vue.use(VueEllipseProgress);


import App from "./App.vue";

// Import the Auth0 configuration
import auth0config from "./auth/config";

// Import the plugin here
import { Auth0Plugin } from "./auth";

import axios from 'axios';
import VueAxios from 'vue-axios'

let domain = process.env.VUE_APP_AUTH0_DOMAIN;
let clientId = process.env.VUE_APP_AUTH0_CLIENT_ID;
let audience = process.env.VUE_APP_AUTH0_AUDIENCE;

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
    domain,
    clientId,
    audience,
    onRedirectCallback: (appState :any) => {
      router.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
      );
    }
  });

Vue.component('v-select', vSelect)

Vue.component("breadcumb", Breadcumb);

import { BootstrapVue, BootstrapVueIcons, BIcon, BIconArrowUp, BIconArrowDown } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons)
Vue.component('BIcon', BIcon)
Vue.component('BIconArrowUp', BIconArrowUp)
Vue.component('BIconArrowDown', BIconArrowDown)

Vue.use(GullKit);

Vue.router = router;
Vue.use(VueRouter);

Vue.use(VueAxios, axios)
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  config,
  i18n,
  render: h => h(App)
}).$mount("#app");
