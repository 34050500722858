import VueRouter from "vue-router"

import store from "./store";
// import {isMobile} from "mobile-device-detect";
import NProgress from "nprogress";

import { authGuard } from "./auth/guard";


// function loadView(view) {
//   return () => import(`./views/app/${view}.vue`);
// }

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "open",
  routes: [
    // Dashboard Section
    {
      path: "/",
      component: () => import("./views/app"), //webpackChunkName app
      redirect: "/comfort-living",
      children: [
        {
          path: "/comfort-living",
          component: () => import("./views/app/comfort"),
          redirect: "/comfort-living/home",
          children: [
            {
              path: "home",
              component: () => import("./views/app/comfort/home")
            },
            {
              path: "aanvragen",
              component: () => import("./views/app/comfort/aanvragen")
            },
            {
              path: "dank",
              name: "dank",
              component: () => import("./views/app/comfort/dank")
            },
            {
              path: "demo",
              component: () => import("./views/app/comfort/demo")
            },
            {
              path: "personal",
              name: "personal",
              beforeEnter: authGuard,
              component: () => import("./views/app/comfort/personal")
            }
          ]
        },
      ]
    },
    {
      path: "/error",
      component: () => import("./views/app/pages/error")
    },
    {
      path: "*",
      component: () => import("./views/app/pages/notFound")
    }
  ],
  scrollBehavior() {
    return {x: 0, y: 0};
  }
});

router.beforeEach((to, from, next) => {
  if(to.query.error && to.path !== "/error") {
    console.log(to.query.error_description);
    next({
        path: '/error',
        query: to.query
    });
  }

  // If this isn't an initial page load.
  if (to.path) {
    NProgress.start();
    NProgress.set(0.1);
  }

  next();
});

router.afterEach(() => {
  // Remove initial loading
  const gullPreLoading = document.getElementById("loading_wrap");
  if (gullPreLoading) {
    gullPreLoading.style.display = "none";
  }
  // Complete the animation of the route progress bar.
  setTimeout(() => NProgress.done(), 500);
  // NProgress.done();
  // if (isMobile) {
  if (window.innerWidth <= 1200) {
    // console.log("mobile");
    store.dispatch("changeSidebarProperties");
    if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
      store.dispatch("changeSecondarySidebarProperties");
    }

    if (store.getters.getCompactSideBarToggleProperties.isSideNavOpen) {
      store.dispatch("changeCompactSidebarProperties");
    }
  } else {
    if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
      store.dispatch("changeSecondarySidebarProperties");
    }

    // store.state.sidebarToggleProperties.isSecondarySideNavOpen = false;
  }
});

export default router;
