import { getInstance } from "./index";

import axios from "axios"

export const authGuard = (to, from, next) => {
    const authService = getInstance();
    const fn = () => {
      // If the user is authenticated, continue with the route
      if (authService.isAuthenticated) {
        authService.getTokenSilently().then(function(token) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        });
        return next();
      }

      // Otherwise, log in
      authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    };

    // If loading has already finished, check our auth state using `fn()`
    if (!authService.loading) {
      return fn();
    }

    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch("loading", loading => {
      if (loading === false) {
        return fn();
      }
    });
};
