<template>
      <b-row>
        <b-col>
          <b-card-title>
            <span class="headline">{{ message }}</span>
          </b-card-title>
          <b-row style="justify-content:center">
            <b-button variant="primary m-1" flat @click="emit_no">{{ no }}</b-button>
            <b-button variant="danger m-1" flat @click="emit_yes">{{ yes }}</b-button>
          </b-row>
        </b-col>
      </b-row>
</template>

<script>
  export default {
    name: "AreYouSure",
      data: () => ({
          dialog: false,
      }),
      methods: {
        emit_no() {
          this.$emit("no", this.data);
        },
        emit_yes() {
          this.$emit("yes", this.data);
        }
      },
      props: {
        message: {
          type: String,
          default: "Are you sure?"
        },
        title: {
          type: String,
          default: "Are you sure?"
        },
        yes: {
          type: String,
          default: "Yes"
        },
        no: {
          type: String,
          default: "No"
        },
        data: {
          type: Object,
          default: null
        }
      }
  }
</script>
